












































import { Component, Vue } from "vue-property-decorator";
@Component
export default class ClientDialog extends Vue {
  private dialog: boolean = false;
  private menu: boolean = false;
  private date: string = new Date().toISOString().substr(0, 10);

  public openDialog() {
    this.dialog = true;
  }
}
