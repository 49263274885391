



































import { Component, Mixins, Watch } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import ClientCreateDialog from "@/components/client/ClientCreateDialog.vue";
import ClientSetRate from "@/components/client/ClientSetRate.vue";
import ClientForm from "@/components/client/ClientForm.vue";
import ClientModule from "@/store/modules/client-module";
import { ClientInterface } from "@/types/client.type";
import { SnackbarModule } from "@/store/modules/snackbar-module";
import { RoleMixin } from "@/mixins/role-mixin";
import SearchField from "@/components/text-field/SearchField.vue";
import ClientCreateButton from "@/components/client/ClientCreateButton.vue";
import Header from "@/components/Header.vue";
import TableComponent from "@/components/TableComponent.vue";
import ConfirmationDialog from "@/components/utils/ConfirmationDialog.vue";

@Component({
  components: {
    ClientCreateDialog,
    ClientSetRate,
    ClientForm,
    ConfirmationDialog,
    SearchField,
    ClientCreateButton,
    Header,
    TableComponent,
  },
})
export default class ClientList extends Mixins(RoleMixin) {
  private headers: DataTableHeader[] = [
    {
      text: "Name",
      value: "name",
    },
    {
      text: "Action",
      value: "action",
      align: "center",
      sortable: false,
    },
  ];

  private dialogClient: boolean = false;

  private id: number | null | string = 1;

  private on: boolean = false;

  private search: string = "";

  private openClientDialog() {
    (
      this.$refs.dialogEdit as Vue & {
        openDialog: () => boolean;
      }
    ).openDialog();
  }

  get clients(): ClientInterface[] {
    return ClientModule.clients;
  }

  private async created() {
    await ClientModule.getClients();
  }

  private openCreateClientDialog() {
    (this.$refs.dialog as Vue & { openDialog: () => boolean }).openDialog();
  }

  private updateSearch(txt: string) {
    this.search = txt;
  }

  private setClientId(id: number) {
    this.id = id;
  }

  private async deleteClient() {
    try {
      await ClientModule.deleteClient(this.id as number);
    } catch (exp) {
      const error = exp.toJSON();
      await SnackbarModule.setSnack({
        color: "error",
        message: error.message,
      });
      return;
    }
    await SnackbarModule.setSnack({
      color: "success",
      message: "Delete client successfully!",
    });
  }

  private clickDelete(data: any) {
    this.setClientId(data.id);
    (
      this.$refs.deleteDialog as Vue & {
        openDialog: () => boolean;
      }
    ).openDialog();
  }

  private clickedRow(data: any) {
    this.setClientId(data.id);
    this.openClientDialog();
  }
}
