













import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import ClientForm from "@/components/client/ClientForm.vue";
import { SnackbarModule } from "@/store/modules/snackbar-module";
import { RoleMixin } from "@/mixins/role-mixin";

@Component({
  components: {
    ClientForm,
  },
})
export default class ClientDialog extends Mixins(RoleMixin) {
  private dialog: boolean = false;

  @Prop({
    default: true,
    type: Boolean,
  })
  private readonly editMode!: boolean;

  @Prop({
    type: [Number, String],
    default: null,
  })
  private readonly clientId!: number | null | string;

  public openDialog() {
    this.dialog = true;
  }

  private simpleCloseDialog() {
    this.dialog = false;
  }

  private closeDialog(success: boolean) {
    if (this.editMode) {
      if (success) {
        SnackbarModule.setSnack({
          color: "success",
          message: "Edit client successfully!",
        });
      } else {
        SnackbarModule.setSnack({
          color: "error",
          message: "Failed to edit client",
        });
      }
    } else {
      if (success) {
        SnackbarModule.setSnack({
          color: "success",
          message: "Create client successfully!",
        });
      } else {
        SnackbarModule.setSnack({
          color: "error",
          message: "Failed to create client",
        });
      }
    }

    this.dialog = false;
  }
}
