
















import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {},
})
export default class ClientCreateButton extends Vue {
  @Prop({
    type: Function,
  })
  private click!: () => void;
}
